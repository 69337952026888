import ApiScopes from 'src/app/core/config/api-scopes';

export const environment = {
  production: false,
  appTitle: 'SuperTrak Config - Dev',
  potApiBaseUrl: 'api-dev.supertrakconveyance.com',
  msalConfig: {
    system: {
      tokenRenewalOffset: undefined,
    },
    auth: {
      clientId: 'cec2cba0-5cf3-450c-9004-da5e5d158dad',
    },
    b2cConfig: {
      policies: {
        signIn: {
          name: 'B2C_1A_Local_Idp_SignIn',
          authority:
            'https://devsupertrakpot.b2clogin.com/devsupertrakpot.onmicrosoft.com/B2C_1A_Local_Idp_SignIn',
        },
        editProfile: {
          name: 'b2c_1_profileeditingclientapp',
          authority:
            'https://devsupertrakpot.b2clogin.com/devsupertrakpot.onmicrosoft.com/b2c_1_profileeditingclientapp',
        },
        signUp: {
          name: 'b2c_1a_signup',
          authority:
            'https://devsupertrakpot.b2clogin.com/devsupertrakpot.onmicrosoft.com/b2c_1a_signup',
        },
      },
      authorityDomain: 'devsupertrakpot.b2clogin.com',
    },
  },
  apiConfig: {
    scopes: [ApiScopes.development.read, ApiScopes.development.write],
    uri: '',
  },
};
